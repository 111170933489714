<template>
  <div class="moneylog">
    <div class="searchw">
      <Form inline :label-width="52">
        <FormItem label="用户ID" :label-width="53" style="width: 150px">
          <Input maxlength="10" v-model="posdata.id" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="用户手机" :label-width="66" style="width: 166px">
          <Input maxlength="11" v-model="posdata.user_phone" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="用户昵称" :label-width="66" style="width: 166px">
          <Input maxlength="30" v-model="posdata.wx_name" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="变动类型" :label-width="66" style="width: 180px">
          <Select transfer v-model="posdata.type" :class="[posdata.type=='0'?'no-sel':'']" @on-change="search">
            <Option value="0">不限</Option>
            <Option v-for="(p,k) in cfg.mtype" :value="k" :key="k">{{p}}</Option>
          </Select>
        </FormItem>
        <FormItem label="来源ID" :label-width="53" style="width: 150px">
          <Input maxlength="10" v-model="posdata.luid" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="来源手机" :label-width="66" style="width: 166px">
          <Input maxlength="11" v-model="posdata.luser_phone" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="来源昵称" :label-width="66" style="width: 166px">
          <Input maxlength="30" v-model="posdata.lwx_name" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="反查类别" :label-width="66" style="width: 150px">
          <Select transfer v-model="posdata.ltype" :class="[posdata.ltype=='0'?'no-sel':'']" @on-change="search">
            <Option v-for="(p,k) in cfg.ltype" :value="k" :key="k">{{p}}</Option>
          </Select>
        </FormItem>
        <FormItem label="变动时间" :label-width="66" style="width: 326px">
          <DatePicker transfer type="date" format="yyyy-MM-dd" placeholder="起始时间" style="width: 118px" @on-change="seldate($event,'start_time')"></DatePicker>
          <span style="color: #515a6e;"> ~ </span>
          <DatePicker transfer type="date" format="yyyy-MM-dd" placeholder="结束时间" style="width: 118px" @on-change="seldate($event,'end_time')"></DatePicker>
        </FormItem>
        <FormItem label="" :label-width="0">
          <Button type="default" icon="ios-search" @click="search" @keyup.13="search">搜索</Button>
        </FormItem>
      </Form>
    </div>
    <Table size="small" :columns="columns" :data="data.data" :loading="table_loading">
      <template slot-scope="row" slot="userinfo">
        <div class="uinfow">
          <div class="headimg"><viewer :options="{'url': 'data-source'}"><img :src="row.row.head_img+'?x-oss-process=style/headimg'" :data-source="row.row.head_img" /></viewer></div>
          <div class="uinfo">
            <div>昵称：<span>{{row.row.wx_name||'--'}}</span></div>
            <div>手机：<span>{{row.row.user_phone||'--'}}</span></div>
            <div>性别：<span>{{row.row.gender||'--'}}</span></div>
            <div v-for="(p,k) in row.row.ninfo" :key="k">{{cfg.platform[p.pid]||''}}UID：<span>{{p.num||'--'}}</span></div>
            <div>等级：<span>{{cfg.level[row.row.level_id]||'--'}}</span></div>
            <div>团队业绩：<span>{{row.row.team_achiev||'0.00'}}</span>元</div>
            <div>个人业绩：<span>{{row.row.achiev||'0.00'}}</span>元</div>
            <div>昨日业绩：<span>{{row.row.yes_achiev||'0.00'}}</span>元</div>
            <div>佣金余额：<span>{{row.row.money||'0.00'}}</span>元</div>
          </div>
        </div>
      </template>
      <template slot-scope="row" slot="affect">
        <div class="afels">变动前：{{row.row.before_money||'0.00'}}</div>
        <div class="afels">变动金额：<span>{{row.row.affect_money>0?'+'+row.row.affect_money:row.row.affect_money}}</span></div>
        <div class="afels">变动后：{{row.row.balance||'0.00'}}</div>
        <div class="afels">变动类型：<span>{{row.row.typetxt||'--'}}</span></div>
      </template>
      <template slot-scope="row" slot="low">
        <div class="uinfow" v-if="row.row.lower_uid">
          <div class="headimg"><img v-viewer :src="row.row.lhead_img" /></div>
          <div class="uinfo">
            <div>昵称：<span>{{row.row.lwx_name||'--'}}</span></div>
            <div>手机：<span>{{row.row.luser_phone||'--'}}</span></div>
            <div>性别：<span>{{row.row.lgender||'--'}}</span></div>
            <div>等级：<span>{{cfg.level[row.row.llevel_id]||'--'}}</span></div>
            <div>团队业绩：<span>{{row.row.lteam_achiev||'0.00'}}</span>元</div>
            <div>个人业绩：<span>{{row.row.lachiev||'0.00'}}</span>元</div>
            <div>昨日业绩：<span>{{row.row.lyes_achiev||'0.00'}}</span>元</div>
            <div>佣金余额：<span>{{row.row.lmoney||'0.00'}}</span>元</div>
          </div>
        </div>
        <div class="uinfow" v-else>
          <div class="headimg"></div>
          <div class="uinfo emptyrec">无关联下级用户</div>
        </div>
      </template>
      <template slot-scope="row" slot="remark">
        {{row.row.remark||'--'}}{{row.row.platform?' ，平台：':''}}{{row.row.platform||''}}
      </template>
    </Table>
    <div class="footer-m">
      <div class="fexport-btn">
        <Button icon="md-download" @click="export_excel" :loading="export_loading">导出Excel</Button>
      </div>
      <Page class-name="pagew page-r" :total="data.total" :current="data.current_page" :page-size="data.per_page" :page-size-opts="[15,30,50,100]" show-sizer show-total @on-change="page" @on-page-size-change="change_pagesize" />
    </div>
  </div>
</template>
<script>
import XLSX from "xlsx";

export default {
  name: 'MoneyLog',
  data(){
    return {
      columns:[
        {title: '记录ID',key: 'id',width:100},
        {title: '用户信息',slot: 'userinfo',minWidth:220},
        {title: '佣金变动',slot: 'affect',minWidth:150},
        {title: '奖励来源',slot: 'low',minWidth:220},
        {title: '备注',slot:'remark', minWidth:200},
        {title: '变动时间',key:'add_time',minWidth:130},
      ],
      data:{},
      cfg:{mtype: {},platform: {},level:{},ltype:[]},
      table_loading:false,
      export_loading:false,
      posdata:{
        id:'',
        user_phone:'',
        wx_name:'',
        type:'0',
        luid:'',
        luser_phone:'',
        lwx_name:'',
        ltype:0,
        start_time:'',
        end_time:'',
        page:1,
        page_size:15
      },
    }
  },
  created() {
    let query={};
    if(typeof this.$route.query.id!="undefined"){
      this.posdata.id=this.$route.query.id;
      query.id=this.$route.query.id||'';
    }
    if(typeof this.$route.query.type!="undefined"){
      this.posdata.type=this.$route.query.type;
      query.type=this.$route.query.type;
    }
    this.$router.push({query});
    this.$store.commit('add_tagsview',this.$route);
    this.init();
  },
  watch:{
    '$route.query':function (query){
      if(query.newpage==1){
        query={'id':query.id||'','type':query.type||'0'};
        Object.assign(this.posdata,query);
        this.$router.push({query});
        this.$store.commit('add_tagsview',this.$route);
        this.init();
      }
    }
  },
  methods:{
    init:function (){
      var _this=this;
      this.table_loading=true;
      this.requestApi('/adm/moneylog.html',this.posdata).then(function (res){
        _this.table_loading=false;
        if(res.status==1){
          _this.data=res.data;
          _this.cfg=res.cfg;
        }
      })
    },
    /**
     * 搜索
     */
    search:function (){
      this.posdata.page=1;
      this.init();
    },
    /**
     * 选择时间
     */
    seldate:function (event,key){
      this.posdata[key]=event;
    },
    /**
     * 翻页
     */
    page:function (page){
      this.posdata.page=page;
      this.init();
    },
    /**
     * 切换分页条数
     */
    change_pagesize:function (size){
      this.posdata.page_size=size;
      if(this.posdata.page==1) this.init();
    },
    /**
     * 导出Excel
     */
    export_excel:function (){
      let _this=this,title={
        id:'记录ID',
        wx_name:'用户昵称',
        user_phone:'用户手机',
        gender:'性别',
        level_id:'等级',
        team_achiev:'团队业绩',
        achiev:'个人业绩',
        yes_achiev:'昨日业绩',
        money:'佣金余额',
        before_money:'变动前佣金余额',
        affect_money:'变动金额',
        balance:'变动后佣金余额',
        typetxt:'变动类型',
        remark:'备注',
        add_time:'变动时间'
      };
      this.export_loading=true;
      this.requestExcel('/adm/export_moneylog.html',title,this.posdata).then(function (res){
        _this.export_loading=false;
        if(res.status!=1) return _this.alertError(res.msg);
        const sheet=XLSX.utils.aoa_to_sheet(res.data);
        const book = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(book, sheet, 'Sheet1');
        XLSX.writeFile(book, '佣金流水.xlsx');
      });
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/css/finance/moneylog";
</style>